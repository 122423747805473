import { elementScrollIntoView, polyfill } from "seamless-scroll-polyfill";

if (typeof window !== "undefined") {
  polyfill();
}

// workaround for safari
export function scrollToSection({
  element,
  id
}: {
  element?: HTMLElement;
  id?: string;
}) {
  if (typeof window !== "undefined") {
    const scrollToElem = id ? document.getElementById(id) : element;
    smoothScrolling(scrollToElem);
  }
}

function smoothScrolling(element) {
  if (typeof window !== "undefined") {
    elementScrollIntoView(element, {
      behavior: "smooth",
      block: "start",
      inline: "start"
    });
  }
}
