// gtm stands for google tag manager
// this requires the user's permission
import TagManager from "react-gtm-module";

const GOOGLE_TAG_MANAGER_ID = "GTM-5LHT772";

export const initGtm = () => {
  TagManager.initialize({
    gtmId: GOOGLE_TAG_MANAGER_ID
  });
};
