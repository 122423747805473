import React, { Context, useState } from "react";
import { IArticle } from "../components/article/types";
import { IJob } from "../components/jobOffering/types";
import { ILocation } from "../templates/locations";

export interface IContent {
  jobs: IJob[];
  highlightedArticles: IArticle[];
  locations: ILocation[];
}

const ContentContext: Context<{
  content: IContent;
  setContent: (content: IContent) => void;
}> = React.createContext<{
  content: IContent;
  setContent: (content: IContent) => void;
}>({
  content: null,
  setContent: (content: IContent) => {}
});

const ContentProvider: React.FC = ({ children }) => {
  const [content, setContent] = useState<IContent>(null);

  return (
    <ContentContext.Provider
      value={{
        content,
        setContent
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export default ContentContext;
export { ContentProvider };
