import React, { Context, useState } from "react";

export const HeaderContext: Context<{
  blendMode: boolean;
  headerColor: string;
  headerHeight: number;
  stickyHeaderHeight: number;
  setBlendMode: (value: boolean) => void;
  setHeaderColor: (color: string) => void;
  setHeaderHeight: (height: number) => void;
  setStickyHeaderHeight: (height: number) => void;
}> = React.createContext({
  blendMode: false,
  headerColor: "",
  headerHeight: 96,
  stickyHeaderHeight: 64,
  setBlendMode: (value: boolean) => {},
  setHeaderColor: (color: string) => {},
  setHeaderHeight: (height: number) => {},
  setStickyHeaderHeight: (height: number) => {}
});

const HeaderProvider: React.FC = ({ children }) => {
  const [blendMode, setBlendMode] = useState(false);
  const [headerColor, setHeaderColor] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0);

  return (
    <HeaderContext.Provider
      value={{
        blendMode,
        headerColor,
        headerHeight,
        stickyHeaderHeight,
        setBlendMode,
        setHeaderColor,
        setHeaderHeight,
        setStickyHeaderHeight
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderContext;
export { HeaderProvider };
