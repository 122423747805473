import React from "react";
import "./src/styles/index.css";
import { ContentProvider } from "./src/contexts/ContentContext";
import { HeaderProvider } from "./src/contexts/HeaderContext";
import { initGtm } from "./src/helpers/gtm";
import { scrollToSection } from "./src/helpers/scrolling";

export const wrapRootElement = ({ element }) => (
  <ContentProvider>
    <HeaderProvider>{element}</HeaderProvider>
  </ContentProvider>
);

export const shouldUpdateScroll = ({
  getSavedScrollPosition,
  prevRouterProps,
  routerProps: { location }
}) => {
  if (location.hash) {
    setTimeout(() => {
      scrollToSection({ id: location.hash.split("#").join("") });
    });

    return false;
  }

  // for cases where the path is the same but the query params differ, keep the same scrollY value.
  // (e.g. for /jobs where query params are dynamically changed if filters are changed)
  if (
    prevRouterProps?.location?.pathname === location.pathname &&
    prevRouterProps?.location?.search !== location.search
  ) {
    const scrollEl = document.querySelector("html");

    scrollEl.scrollTo(0, scrollEl.scrollTop);

    return false;
  }

  // fix gatsby issue where scroll position is maintained when switching between pages
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 100);

  return true;
};

export const onClientEntry = () => {
  // Initialize Google Tag Manager
  initGtm();

  // Initialize Microsoft Clarity
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/p0jfyez2n5";
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", "p0jfyez2n5");
};